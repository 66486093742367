








































































import { Component, Vue } from "vue-property-decorator";
import MainHeader from "@/components/main-header/main-header.vue";
import echarts from "echarts";
import { GetText, GetTupu } from "@/request/tupu";
import Empty from "@/components/empty.vue";
@Component({
  components: {
    MainHeader,
    Empty,
  },
})
export default class Name extends Vue {
  private tupuData: any = {};
  private search: any = {};
  private page: any = "default";
  private options: any = [];
  private colors: any = [
    "#FFA7CC",
    "#46AAAA",
    "#FFAA6A",
    "#A185D3",
    "#82D1F1",
    "#EC7E68",
    "#F6C63C",
    "#7784A2",
    "#75DDB5",
    "#759FFC",
    "#FFA7CC",
    "#46AAAA",
    "#FFAA6A",
    "#A185D3",
    "#82D1F1",
    "#EC7E68",
    "#F6C63C",
    "#7784A2",
    "#75DDB5",
    "#759FFC",
    "#FFA7CC",
    "#46AAAA",
    "#FFAA6A",
    "#A185D3",
    "#82D1F1",
    "#EC7E68",
    "#F6C63C",
    "#7784A2",
    "#75DDB5",
    "#759FFC",
    "#FFA7CC",
    "#46AAAA",
    "#FFAA6A",
    "#A185D3",
    "#82D1F1",
    "#EC7E68",
    "#F6C63C",
    "#7784A2",
    "#75DDB5",
    "#759FFC",
  ];
  private remoteMethod(e: any) {
    const params: any = {
      params: {
        search: e,
      },
    };
    GetText(this, params).then((res: any) => {
      this.options = res;
    });
  }
  private categories: any = [];
  private links: any = [];
  private nodes: any = [];
  private focus() {
    this.remoteMethod("");
  }
  private goSearch() {
    if (!this.search.id) {
      this.$message.warning("请搜索选择搜索词");
      return;
    }
    this.page = "tupu";
    const data: any = {
      entity_id: this.search.id,
      entity_name: this.search["实体名称"],
      entity_type: this.search["语义类型"],
    };
    this.tupuData = {};
    this.drawTupu(data);
  }
  private unique(arr: any, keys: any) {
    const arr1: any = [];
    const arr2: any = [];

    for (let i = 0; i < arr.length; i++) {
      let str: any = "";
      keys.forEach((ele: any) => {
        str += arr[i][ele] + "-";
      });
      if (!arr1.includes(str)) {
        // 数组中没有就往里添加
        arr1.push(str);
        arr2.push(JSON.parse(JSON.stringify(arr[i])));
      }
    }
    return arr2;
  }
  private drawTupu(d: any) {
    const params: any = {
      params: {
        entity_id: d.entity_id,
        entity_name: d.entity_name,
        entity_type: d.entity_type,
      },
    };
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    GetTupu(this, params).then((data: any) => {
      loading.close();
      if (this.tupuData.nodes) {
        const categories = this.tupuData.categories.concat(data.categories);
        data.nodes.forEach((ele: any) => {
          ele.type = "实体";
        });
        const nodes = this.tupuData.nodes.concat(data.nodes);
        const links = this.tupuData.links.concat(data.links);
        this.tupuData.categories = this.unique(categories, ["name"]);
        this.tupuData.nodes = this.unique(nodes, ["id"]);
        this.tupuData.links = this.unique(links, ["name", "source", "target"]);
      } else {
        this.tupuData = data;
      }
      this.tupuData.nodes.forEach((ele: any, index: any) => {
        if (ele.type === "目标") {
          ele.symbol = "roundRect";
          ele.symbolSize = 25;
        } else if (ele.type === "实体") {
          ele.symbolSize = 20;
        } else {
          ele.symbolSize = 1;
        }
      });
      const Chart: any = echarts.init(this.$refs.Echarts as HTMLCanvasElement);
      const options: any = {
        color: this.colors,
        title: {
          text: "",
          subtext: "",
          top: "bottom",
          left: "right",
        },
        tooltip: {},
        legend: [
          {
            // selectedMode: 'single',
            data: this.tupuData.categories.map(function (a: any) {
              return a.name;
            }),
          },
        ],
        animation: false,
        series: [
          {
            name: "",
            type: "graph",
            layout: "force",
            data: this.tupuData.nodes,
            links: this.tupuData.links,
            categories: this.tupuData.categories,
            zoom: 3,
            roam: true,
            force: {
              layoutAnimation: false,
            },
            label: {
              show: true,
              position: "inside",
              formatter: "{b}",
            },
            edgeLabel: {
              show: true,
              formatter: (res: any) => {
                return res.data.name;
              },
            },
            edgeSymbol: ["", "arrow"],
            lineStyle: {
              color: "#666",
              width: 3,
              curveness: 0.1,
            },
            emphasis: {
              show: false,
              focus: "adjacency",
              lineStyle: {
                width: 6,
              },
            },
          },
        ],
      };
      Chart.setOption(options, true);
      // 点击事件
      Chart.off("click");
      Chart.on("click", (p: any) => {
        const data: any = {
          entity_id: p.data.id,
          entity_name: p.data["name"],
          entity_type: p.data["category"],
        };
        this.drawTupu(data);
      });
    });
  }
}
