import axios, { resHandle } from "@/plugins/axios";
/**
 * @description 获取图谱搜索词
 * @param vCom Vue组件
 */
const GetTupu = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get("api/knowledge/entity/tupu", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 获取图谱数据
 * @param vCom Vue组件
 */
const GetText = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get("api/knowledge/entity/search", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
export { GetText, GetTupu }